export default {
    title: 'Dc Lead Time Precision',
    location: '/business/fuo/dc-lead-time-precision',
    tabs: [
        {
            name: 'By Country',
            link: `/business/fuo/dc-lead-time-precision`
        },
        {
            name: 'Trend',
            link: `/business/fuo/dc-lead-time-precision/:market`
        },
        // {
        //     name: 'Top 10',
        //     link: '/customer/customer-order-quality/'
        // }
    ]
}
